import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        dataLayer: Object,
        context: String,
    }

    routesDefinitionType = {
        "app-home-index": "home",
        serp: "listing_emploi",
    }
    routesDefinitionSubject = {
        "app-home-index": "home",
        "offer-detail": "offer_detail",
        serp: "offer",
    }

    connect() {
        this.dataLayer = window.dataLayer || []
    }

    pageload(event) {
        const parsedContext = JSON.parse(this.contextValue)
        const pageDisplay = {
            event: "page.display",
            event_name: "page.display",
            page_type: this.routesDefinitionType[parsedContext.route],
            page_subject: this.routesDefinitionSubject[parsedContext.route],
            user_logged_in: parsedContext.user_category !== "Visiteur",
            user_category: parsedContext.user_category,
            partner_id: parsedContext.platform.intranetCode,
            partner_label: parsedContext.platform.label,
            ...event.params.pageload,
        }
        this.pushToGtm(pageDisplay)
    }

    productClick(event) {
        const productClickEvent = {
            event: "product.click",
            event_name: "product.click",
            ...event.params.productClick,
        }
        this.pushToGtm(productClickEvent)
    }

    productDisplay(event) {
        const productDisplayEvent = {
            event: "product.display",
            event_name: "product.display",
            ...event.params.productDisplay,
        }
        this.pushToGtm(productDisplayEvent)
    }

    productPageDisplay(event) {
        const productPageDisplayEvent = {
            event: "product.page_display",
            product_data: event.params.productPageDisplay,
        }
        this.pushToGtm(productPageDisplayEvent)
    }

    menuTrigger(event) {
        const genericMenuEvent = {
            event: "generic",
            event_name: "menu_click",
            wording: event.params.menuTrigger?.wording ?? event.target.innerText,
            ...event.params.menuTrigger,
        }
        this.pushToGtm(genericMenuEvent)
    }

    ctaTrigger(event) {
        const genericCtaEvent = {
            event: "generic",
            event_name: "cta_click",
            wording: event.params.ctaTrigger?.wording ?? event.target.innerText,
            ...event.params.ctaTrigger,
        }
        this.pushToGtm(genericCtaEvent)
    }

    genericTrigger(event) {
        const genericEvent = {
            event: "generic",
            ...event.params.genericTrigger,
        }
        this.pushToGtm(genericEvent)
    }

    pushToGtm(data) {
        this.dataLayer.push(data)
    }
}
