import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["element"]
    static values = { delay: Number }

    connect() {
        this.#initializeFadedElements()
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("visible")
                    }
                })
            },
            { threshold: 0.2 },
        )

        this.elementTargets.forEach((el) => {
            observer.observe(el)
        })
    }

    #initializeFadedElements() {
        this.elementTargets.forEach((el) => {
            if (!el.getBoundingClientRect().top < window.innerHeight) {
                el.classList.add("fade-in")
            }
        })
    }
}
