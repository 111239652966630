import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "villesCollectionContainer",
        "regionsCollectionContainer",
        "departementsCollectionContainer",
        "postalCodesCollectionContainer",
        "perimetersArea",
        "input",
    ]

    static values = {
        prototypePostalcodes: String,
        prototypeVilles: String,
        prototypeRegions: String,
        prototypeDepartements: String,
    }

    clearCollectionContainer(container) {
        while (container.firstChild) {
            container.removeChild(container.firstChild)
        }
    }

    connect() {
        this.checkPerimetersShow()
    }

    resetHidden() {
        this.clearCollectionContainer(this.postalCodesCollectionContainerTarget)
        this.clearCollectionContainer(this.villesCollectionContainerTarget)
        this.clearCollectionContainer(this.regionsCollectionContainerTarget)
        this.clearCollectionContainer(this.departementsCollectionContainerTarget)
    }

    checkPerimetersShow() {
        if (this.villesCollectionContainerTarget.children.length > 0) {
            this.perimetersAreaTarget.classList.remove("hidden")
        } else {
            this.perimetersAreaTarget.classList.add("hidden")
            this.perimetersAreaTarget.selectedIndex = 0
        }
    }

    fillSuggestion(e) {
        this.addLocation(e.detail.item.id, e.detail.item.type)

        if (e.detail.item.type === "city") {
            this.addLocation(e.detail.item.postalCode, "postalCode")
            this.addLocation(e.detail.item.departement.id, "department")
            this.addLocation(e.detail.item.departement.region.id, "region")
        }

        if (e.detail.item.type === "department") {
            this.addLocation(e.detail.item.region)
        }

        this.inputTarget.value = e.detail.item.label

        this.checkPerimetersShow()
    }

    addLocation(suggestionId, suggestionType) {
        const item = document.createElement("div")
        let container = null

        if (suggestionType === "postalCode") {
            console.log(suggestionId)
            item.innerHTML = this.prototypePostalcodesValue.replace(/__name__/g, "")
            container = this.postalCodesCollectionContainerTarget
        }

        if (suggestionType === "city") {
            item.innerHTML = this.prototypeVillesValue.replace(/__name__/g, "")
            container = this.villesCollectionContainerTarget
        }

        if (suggestionType === "region") {
            item.innerHTML = this.prototypeRegionsValue.replace(/__name__/g, "")
            container = this.regionsCollectionContainerTarget
        }

        if (suggestionType === "department") {
            item.innerHTML = this.prototypeDepartementsValue.replace(/__name__/g, "")
            container = this.departementsCollectionContainerTarget
        }

        const input = item.querySelector("input")

        if (container && input) {
            input.value = suggestionId
            container.appendChild(item)
        }
    }
}
