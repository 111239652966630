import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "jobTitlesCollectionContainer",
        "sectorsCollectionContainer",
        "companiesCollectionContainer",
        "input",
    ]

    static values = {
        prototypeJobTitles: String,
        prototypeSectors: String,
        prototypeCompanies: String,
    }

    clearCollectionContainer(container) {
        while (container.firstChild) {
            container.removeChild(container.firstChild)
        }
    }

    resetHidden() {
        this.clearCollectionContainer(this.jobTitlesCollectionContainerTarget)
        this.clearCollectionContainer(this.sectorsCollectionContainerTarget)
        this.clearCollectionContainer(this.companiesCollectionContainerTarget)
    }

    fillSuggestion(e) {
        const item = document.createElement("div")
        let container = null

        if (e.detail.item.type === "jobTitle") {
            item.innerHTML = this.prototypeJobTitlesValue.replace(/__name__/g, "")
            container = this.jobTitlesCollectionContainerTarget
        }

        if (e.detail.item.type === "sector") {
            item.innerHTML = this.prototypeSectorsValue.replace(/__name__/g, "")
            container = this.sectorsCollectionContainerTarget
        }

        if (e.detail.item.type === "company") {
            item.innerHTML = this.prototypeCompaniesValue.replace(/__name__/g, "")
            container = this.companiesCollectionContainerTarget
        }

        const input = item.querySelector("input")

        if (container && input) {
            input.value = e.detail.item.id
            container.appendChild(item)
            this.inputTarget.value = e.detail.item.label
        }
    }
}
