import { startStimulusApp } from "@symfony/stimulus-bridge"
import Clipboard from "stimulus-clipboard"
import Popover from "./controllers/tooltip_controller"
import ScrollTo from "@stimulus-components/scroll-to"

// Registers Stimulus controllers from controllers.json and in the controllers/ directory
export const app = startStimulusApp(
    require.context("@symfony/stimulus-bridge/lazy-controller-loader!./controllers", true, /\.[jt]sx?$/),
)

// register any custom, 3rd party controllers here
app.register("clipboard", Clipboard)
app.register("popover", Popover)
app.register("scroll-to", ScrollTo)
