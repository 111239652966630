import { Controller } from "@hotwired/stimulus"
import { Editor } from "@tiptap/core"
import { StarterKit } from "@tiptap/starter-kit"
import { Link } from "@tiptap/extension-link"
import { BulletList } from "@tiptap/extension-bullet-list"
import { OrderedList } from "@tiptap/extension-ordered-list"
import { ListItem } from "@tiptap/extension-list-item"
import { Bold } from "@tiptap/extension-bold"
import { Italic } from "@tiptap/extension-italic"
import { Underline } from "@tiptap/extension-underline"
import { Strike } from "@tiptap/extension-strike"

export default class extends Controller {
    static targets = ["editorInput", "hiddenInput", "submit"]
    static values = {
        options: String,
    }

    connect() {
        this.editorInput = new Editor({
            editorProps: {
                attributes: {
                    class: "wysiwyg",
                },
            },
            element: this.editorInputTarget,
            extensions: [
                StarterKit,
                ListItem,
                BulletList,
                OrderedList,
                Bold,
                Underline,
                Strike,
                Italic,
                Link.configure({
                    openOnClick: false,
                    HTMLAttributes: {
                        target: "_blank",
                    },
                }),
            ],
            content: this.hiddenInputTarget.value,
            onUpdate: ({ editor }) => {
                this.hiddenInputTarget.value = editor.getHTML()
            },
        })
        this.listenFocusOnEditorContainerToVisiblySpreadOnParents()
    }

    toggleBulletList(e) {
        e.preventDefault()
        this.editorInput.chain().focus().toggleBulletList().run()
    }

    toggleOrderedList(e) {
        e.preventDefault()
        this.editorInput.chain().focus().toggleOrderedList().run()
    }

    setLink(e) {
        const previousUrl = this.editorInput.getAttributes("link").href
        const url = window.prompt("URL", previousUrl)
        e.preventDefault()
        if (url === null) {
            return
        }
        console.log(url)
        if (url === "") {
            this.editorInput.chain().focus().extendMarkRange("link").unsetLink().run()

            return
        }
        this.editorInput.chain().focus().extendMarkRange("link").setLink({ href: url, target: "_blank" }).run()
    }

    toggleBold(e) {
        e.preventDefault()
        this.editorInput.chain().focus().toggleBold().run()
    }

    toggleItalic(e) {
        e.preventDefault()
        this.editorInput.chain().focus().toggleItalic().run()
    }

    toggleUnderline(e) {
        e.preventDefault()
        this.editorInput.chain().focus().toggleUnderline().run()
    }

    toggleStrike(e) {
        e.preventDefault()
        this.editorInput.chain().focus().toggleStrike().run()
    }

    preventSubmitOnEnterKey(e) {
        if (e.key === "Enter") {
            e.preventDefault()
        }
    }

    listenFocusOnEditorContainerToVisiblySpreadOnParents() {
        const tiptap = this.editorInputTarget.querySelector(".tiptap")
        tiptap.addEventListener("focus", () => {
            const parent = tiptap.parentElement
            if (parent) {
                parent.classList.add("outline")
                tiptap.classList.add("outline-none")
            }
        })
        tiptap.addEventListener("blur", () => {
            const parent = tiptap.parentElement
            if (parent) {
                parent.classList.remove("outline")
                tiptap.classList.remove("outline-none")
            }
        })
    }

    disconnect() {
        this.inputTargets.forEach((input) => {
            input.removeEventListener("keydown", this.preventSubmitOnEnterKey.bind(this))
        })
        this.editorInput.destroy()
    }
}
