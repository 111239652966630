import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["listResumes"]

    onUploadResume(e) {
        if (this.listResumesTarget.tagName === "SELECT") {
            const emptyOption = this.listResumesTarget.querySelector("option[value='']")
            if (emptyOption) {
                this.listResumesTarget.removeChild(emptyOption)
            }

            const option = document.createElement("option")
            option.value = ""
            option.textContent = e.target.files[0].name
            this.listResumesTarget.appendChild(option)
            this.listResumesTarget.value = option.value
        }

        if (this.listResumesTarget.tagName === "INPUT") {
            this.listResumesTarget.classList.remove("hidden")
            this.listResumesTarget.value = e.target.files[0].name
        }
    }
}
