import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "dialog",
        "form",
        "publicationDate",
        "seeSearchResultsButtonWrapper",
        "scrollWindowFilter",
        "mobileScrollWindowFilter",
        "defaultDataForm",
        "seeSearchResultsButton",
    ]
    static classes = ["modale"]
    static values = {
        whenMatching: String,
    }
    defaultDataForm

    connect() {
        this.defaultDataForm = new URLSearchParams(new FormData(this.formTarget)).toString()
    }

    toggleFacetsDialog() {
        document.body.classList.toggle("overflow-hidden")
        let alertBtn = document.querySelector("#make-an-alert-btn")
        let alertBtnWithoutText = document.querySelector("#make-an-alert-btn-without-text")
        if (alertBtnWithoutText?.classList.contains("hidden")) {
            alertBtn?.classList.toggle("hidden")
        } else {
            alertBtnWithoutText?.classList.toggle("hidden")
        }

        for (const classe of this.modaleClasses) {
            this.dialogTarget.classList.toggle(classe)
        }
    }

    refreshFilters() {
        document.querySelector(".searchbar-form").submit()
    }

    removeFilter() {
        document.querySelector(".searchbar-form").submit()
        this.formTarget.submit()
    }

    removeShadowButtonSeeResults() {
        let buttonWrapperFilter = this.seeSearchResultsButtonWrapperTarget
        let windowFilter = this.scrollWindowFilterTarget

        if (buttonWrapperFilter.getBoundingClientRect().top === windowFilter.getBoundingClientRect().bottom) {
            buttonWrapperFilter.classList.remove("shadow-[0px_0px_30px_4px_#0f22664c]")
        } else {
            buttonWrapperFilter.classList.add("shadow-[0px_0px_30px_4px_#0f22664c]")
        }
    }

    checkUpdateFilters() {
        let newForm = new URLSearchParams(new FormData(this.formTarget)).toString()
        return newForm !== this.defaultDataForm
    }

    enableButtonApplyFilters() {
        if (this.checkUpdateFilters()) {
            this.seeSearchResultsButtonTarget.removeAttribute("disabled")
            this.seeSearchResultsButtonTarget.classList.remove("bg-grey-800")
        }
    }
}
