import { Controller } from "@hotwired/stimulus"
import { getComponent } from "@symfony/ux-live-component"

export default class extends Controller {
    async initialize() {
        this.component = await getComponent(document.getElementById("toast-container"))
    }

    connect() {
        this.element.addEventListener("turbo:submit-end", () => {
            this.component.render()
        })
    }

    close() {
        this.element.remove()
    }
}
