import { Controller } from "@hotwired/stimulus"

/**
 * @deprecated Controller in use for an archaic way to do stats
 */
export default class extends Controller {
    static values = {
        whenMatching: String,
    }

    clickTrackMER(event) {
        event.preventDefault()
        const rootUrl = window.location.origin + "/" // Ensure root URL

        const { pageHash: pageHash, label: label, data: data } = event.params.clickTrackMer

        const formData = new URLSearchParams()
        formData.append("pageHash", pageHash)
        formData.append("label", label)
        formData.append("data[offer_es_id]", data.id)
        formData.append("data[offer_title]", data.title)
        formData.append("data[search_type]", data.isExclusive ? "classical_distant" : "classical_exclusive")
        formData.append("isMobile", !window.matchMedia(this.whenMatchingValue).matches)
        formData.append("url", window.location.pathname + window.location.search)

        fetch(rootUrl + "ajax_ct", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formData,
        })
            .then(() => {
                window.location.href = event.target.href
            })
            .catch((error) => {
                console.error("Error:", error)
                window.location.href = event.target.href
            })
    }
}
