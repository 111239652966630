import { Controller } from "@hotwired/stimulus"
import { getComponent } from "@symfony/ux-live-component"
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
    static targets = ["button", "dialog", "frame", "closeBtn", "altCloseBtn"]
    static values = {
        redirectOnClose: String,
        canScroll: Boolean,
    }

    async initialize() {
        this.component = await getComponent(document.getElementById("toast-container"))
    }

    connect() {
        useClickOutside(this, { element: this.frameTarget })
        this.element.addEventListener("turbo:submit-end", (event) => {
            this.onSubmitEnd(event)
            this.component.render()
        })
    }

    clickOutside(event) {
        event.preventDefault()
        this.closeDialog()
    }

    disconnect() {
        this.element.removeEventListener("turbo:submit-end", this.onSubmitEnd.bind(this))
    }

    onSubmitEnd(event) {
        const { success } = event.detail

        if (success) {
            this.closeDialog(event)
        }
    }

    openDialog() {
        this.dialogTarget.showModal()

        if (!this.canScrollValue) {
            document.body.style.overflowY = "hidden"
        }
    }

    closeDialog(e) {
        this.dialogTarget.close()
        this.buttonTarget.blur()
        if (!this.canScrollValue) {
            document.body.style.overflowY = "auto"
        }

        if (this.redirectOnCloseValue) {
            window.open(this.redirectOnCloseValue, "_blank")
        }
    }

    animationBell(e) {
        e.target.querySelector("svg").classList.toggle("ring-animation")
    }
}
