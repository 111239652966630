import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["dialog"]

    static values = {
        redirectOnSubmit: Boolean,
        popupOnDryRun: Boolean,
    }

    connect() {
        if (this.redirectOnSubmitValue) {
            this.redirectAfterSubmit()
        }

        if (this.popupOnDryRunValue) {
            this.popupAfterDryRun()
        }
    }

    redirectAfterSubmit() {
        this.element.addEventListener("turbo:submit-end", (event) => {
            const { success } = event.detail
            const redirectUrl = event.detail.fetchResponse.response.headers.get("X-Redirect-Location")

            if (success && redirectUrl) {
                window.location.href = redirectUrl
            }
        })
    }

    popupAfterDryRun() {
        this.element.addEventListener("turbo:submit-end", (event) => {
            const { success } = event.detail

            if (success) {
                this.dialogTarget.showModal()
            }
        })
    }
}
