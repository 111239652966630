import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["details"]

    connect() {
        window.addEventListener("scroll", () => {
            if (this.detailsTarget.open) {
                this.detailsTarget.removeAttribute("open")
            }
        })
    }
}
